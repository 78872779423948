import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
    selector: 'dialog-container',
    template: `
        <ng-content></ng-content>`
})
export class DialogContainerComponent {
}
