import { Component, Input, ViewChild, TemplateRef, ContentChild } from '@angular/core';
import { Logger } from 'ngx-myia-core';
import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { Observable } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
    selector: 'modal-dialog',
    template: `
        <ngx-smart-modal #dialog [identifier]="identifier" [customClass]="customClass" [dismissable]="dismissable" [escapable]="dismissable" [closable]="false" class="modal{{customClass ? '-' + customClass : ''}}">
            <ng-container *ngIf="visible" [ngTemplateOutlet]="dialogTemplate || contentTemplate" [ngTemplateOutletContext]="{$implicit: modalDialog}"></ng-container>
        </ngx-smart-modal>

    `
})
export class ModalDialogComponent<TDialogData, TDialogResult> {
    @Input() identifier: string;
    @Input() customClass: string;
    @Input() dismissable: boolean;

    @Input() canDeactivate: () => Observable<boolean> | boolean;

    dialogData: TDialogData;
    dialogTemplate: TemplateRef<any>;

    result: Observable<TDialogResult>;

    @ViewChild('dialog', {static: true}) ngxModal: NgxSmartModalComponent;
    @ContentChild(TemplateRef, {static: true}) contentTemplate: TemplateRef<any>;

    destroyed: boolean;

    get visible() {
        return this.ngxModal && this.ngxModal.isVisible();
    }

    get data() {
        return this.ngxModal && this.ngxModal.getData();
    }

    get modalDialog() {
        return this;
    }

    constructor(private _logger: Logger) {
    }

    close(result?: TDialogResult) {
        this.ngxModal.setData(result);
        this.ngxModal.close();
    }
}

